@use "@danfoss/mosaic-sass/dist/entry" as *;

.chip {
    &.theme-success {
        border-color: darken(rgb(20, 197, 16), 10%) !important;
        background-color: cssvar(color-success) !important;
        color: cssvar(color-primary) !important;
    }

    &.theme-warning {
        border-color: darken(rgb(255, 170, 0), 10%) !important;
        background-color: cssvar(color-warning) !important;
        color: cssvar(color-primary) !important;
    }

    &.theme-danger {
        border-color: darken(rgb(226, 0, 15), 10%) !important;
        background-color: cssvar(color-error) !important;
        color: cssvar(color-primary) !important;
    }

    &[aria-disabled="true"] {
        cursor: not-allowed !important;

        > * {
            color: cssvar(color-neutral-strong) !important;
            fill: cssvar(color-neutral-strong) !important;
        }

        &:hover {
            background-color: cssvar(color-primary) !important;
        }
    }

    &:not(.clickable) {
        pointer-events: none;
    }
}

.version-minified {
    min-height: auto !important;
    padding: 4px !important;
    font-size: 12px !important;
    line-height: 1 !important;
}