@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './../../styles/variables.scss' as v;
@use './../../styles/mixins.scss';
@use './../../styles/mosaic.scss';

@include mosaic.override-specificity-module {
    .header {
        background: cssvar(color-primary);
        width: 100%;
        position: relative;
        padding: 0;
        box-shadow: v.$sub-header-box-shadow;

        display: grid;

        $icon-width: 50px;
        $icon-area-width: $icon-width * 2;

        grid-template-columns: $icon-area-width 1fr $icon-area-width;
        align-items: center;
        z-index: 2; // <- We want to prevent the shadow from being covered by the loader overlay of the body below
        @include mixins.chat-header-height;

        .overlay {
            position: absolute;
            inset: 0;
            background-color: rgba(#fff, 0.5);
            z-index: 2;
        }

        // Positioning of icons and input
        .left,
        .center,
        .right {
            //     display: flex;
            // --pad-to-edge: get-spacing(1);
            // --grid-position: 1;
            // grid-row: 1 / span 1;
            // grid-column: var(--grid-position) / span 1;
            //     height: 100%;
            // align-items: center;
            gap: get-spacing(4);
        }

        .left {
            --grid-position: 1;
            display: flex;
            padding-left: var(--pad-to-edge);
        }

        .center {
            padding: get-spacing(1) 0;
            --grid-position: 2;
        }

        .right {
            --grid-position: 3;
            display: flex;
            justify-content: flex-end;
            padding-right: var(--pad-to-edge);
        }

        @include match-breakpoint-up(sm) {
            padding: 0 get-spacing(5);
        }

        @include match-breakpoint-down(sm) {
            .icon {
                --pad-x: #{get-spacing(0.75)};
            }

            .left,
            .right {
                --pad-to-edge: #{get-spacing(3)};
            }
        }
    }
}
