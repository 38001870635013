@use '@danfoss/mosaic-sass/dist/entry' as *;

.system-event {
    width: 100%;
    max-width: 800px;
    margin: auto;
    line-height: 2.5;

    :global(.container) {
        display: flex;
        justify-content: center;
        position: relative;
        color: cssvar(color-object-weak);

        * {
            font-size: 12px;
        }

        :global(.event) {
            background-color: cssvar(color-primary);
            text-align: center;
            justify-content: center;
            align-items: center;

            padding: get-spacing(1.5) get-spacing(3);
            border-radius: get-spacing(3);
            margin: 0 get-spacing(6);
            max-width: 500px;
        }

        &:global(:before) {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 1px;
            top: 50%;
            transform: translate(0, -50%, 0);
            background-color: cssvar(color-object-weakest);
        }
    }
}

.message-with-cta {
    text-decoration: underline;
    display: inline !important;
    color: cssvar(color-object-strong);
}

.message-as-link {
    display: inline-flex;
    color: cssvar(color-object-weak) !important;
    font-weight: normal !important;

    div {
        gap: get-spacing(1);
        display: flex;
        align-items: center;
    }

    .icon {
        display: inline;
        font-size: 24px !important;
        flex: 1 0 get-spacing(6);
    }

    &:hover {
        color: cssvar(color-active-strong) !important;
    }
}