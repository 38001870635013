@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/variables.scss' as var;

.chat-recommendations {
    display: grid;
    gap: get-spacing(4);
    grid-template-columns: repeat(2, 1fr);

    .card {
        padding: get-spacing(3);
    }

    .icon,
    .title {
        display: inline-flex;
        align-items: center;
    }

    grid-template-columns: repeat(2, 1fr);

    &.three-column-layout {
        grid-template-columns: repeat(3, 1fr);

        @include match-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }


    @include match-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}