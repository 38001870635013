@use '@danfoss/mosaic-sass/dist/entry' as *;

:global(.df-mosaic :not(#\#)) .label {
    display: flex;
    align-items: center;
    gap: get-spacing(2);

    label {
        color: cssvar(color-secondary);
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;

        &[aria-disabled='true'] {
            color: cssvar(color-text-base);
        }
    }

    label+*,
    .icon-container>svg {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    .icon-container[aria-disabled='true'] {
        opacity: 0.3;

        &,
        svg {
            cursor: not-allowed;
        }
    }
}