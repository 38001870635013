@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../new-chat-window/NewChatWindow.module.scss" as *;
@use "../../styles/mixins" as mixins;

.chat-window {
    @include chatWindowBaseStyling;
    overflow: hidden;
    position: relative;

    .chat-window-wrapper {
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
    }

    .chat-messages {
        width: 100%;
        overflow-y: auto;
        scroll-behavior: smooth;
        @include mixins.thin-scroll;

        .chat-messages-inner {
            padding: get-spacing(5);

            .message,
            :global(.df-alert) {
                @include mixins.chat-max-width();
            }

            .message {
                padding-right: get-spacing(10); // Dependent for width calc in Message.module.scss 

                &:not(:last-child) {
                    margin-bottom: get-spacing(8);
                }

                @include mixins.mobileDevice {
                    padding-right: 0 !important;
                }
            }

            @include match-breakpoint-down(sm) {
                padding: get-spacing(4);

                .message {
                    padding-right: get-spacing(8);
                }
            }
        }

        .loader-wrapper {
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0;
            top: 0; // <- We don't want the overlay to obstruct the shadow of the chat header 
            max-width: 100%;
            z-index: 1;
        }
    }

    .disclaimer {
        margin-top: get-spacing(8);
    }

    .chat-messages.mobile .message {
        padding-right: 0
    }
}

.awaiting-stream-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}