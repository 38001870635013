@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../persona-preferences/PersonaPreferences.module.scss' as p;
@use './../../styles/variables.scss' as v;
@use '../../styles/mixins';

.history {
    overflow: auto;
    // Side panel flex manipulation
    flex: 1;

    @include mixins.thin-scroll;

    li.active-route .delete-icon:before,
    li:not(.active-route):hover .delete-icon:before {
        background-color: cssvar(color-neutral-weak);
    }
}

.delete-all {
    z-index: 3;
    flex: 1;
    bottom: 0;
    position: sticky;
    padding: get-spacing(3) get-spacing(4);
    display: flex;
    justify-content: flex-end;
    background: cssvar(color-primary);
}

.history-title {
    display: block;
    padding: get-spacing(3) get-spacing(4) get-spacing(2);
}

.group-title {
    top: 0;
    background-color: cssvar(color-primary);
    position: sticky;
    padding: get-spacing(3) get-spacing(4) get-spacing(1);
    font-size: 14px;
    font-weight: 700;
    display: block;
    color: cssvar(color-secondary);
    z-index: 3;

    &:not(:first-of-type) {
        margin-top: get-spacing(4);
    }
}

.list li {
    span {
        z-index: 0;
        max-width: 100%;
        transition: transform 150ms ease-in-out;
        @include mixins.ellipsis(1);
    }

    &:hover .delete-icon {
        opacity: 1;
    }
}

.active-route {
    background-color: cssvar(color-neutral-weak);

    span {
        transform: none;
    }
}

.delete-icon {
    right: get-spacing(2);
    position: absolute;
    @include p.iconChipStyling;
}