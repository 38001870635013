@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/mixins.scss' as mixins;

@mixin loading-shimmer {
    $start: 100%;
    $end: -100%;

    &::before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        animation: skeleton-loading 1800ms infinite cubic-bezier(0.33, 1, 0.68, 1);
        $c1: transparent;
        $c2: rgb(255, 255, 255, 0.75);
        background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));

        @keyframes skeleton-loading {
            0% {
                transform: translateX(#{$start});
            }

            40% {
                transform: translateX(#{$end});
            }

            100% {
                transform: translateX(#{$end});
            }
        }
    }
}

.ui-image {
    height: 100%;
    width: 100%;
    display: inline-block;

    &.loading {
        position: relative;
        overflow: hidden;

        @include loading-shimmer;

        img {
            background-color: cssvar(color-neutral-base) !important;
        }
    }

    img {
        width: 100%;
        height: 100%;

        &:not(.minified) {
            border-radius: 4px;
            border: 1px solid cssvar(color-neutral-base);
        }
    }

    &.cover img {
        object-fit: cover;
    }

    &._error {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: cssvar(color-neutral-base) !important;

        img {
            display: none;
        }
    }
}

.zoom:global(.zoom-image) {
    height: 100%;
    user-select: none;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex: 1;
    padding: get-spacing(4);

    * {
        touch-action: manipulation;
    }

    :global(.swiper) {
        flex: 1;
        width: 100%;
        border-radius: 4px;
        border: 1px solid cssvar(color-neutral-base);
    }

    img {
        border-radius: 0;
        border: none;
    }
}

.actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: get-spacing(6);
    margin-top: get-spacing(4);
}

.slider {
    position: relative;
    width: 100%;
    height: 100%;

    :global(.swiper) {
        width: 100%;
        height: calc(100% - 40px);
    }
}

.custom-image {
    img {
        background-color: cssvar(color-primary);
        padding: get-spacing(4);
    }

    .expand {
        box-sizing: initial !important;
    }
}

// Custom container div
:global(.swiper-thumbs-container) {
    position: relative;

    // <Swiper> - thumb images container
    :global(.swiper-thumbs) {
        $thumb-spacing-fallback: 10px;
        $thumb-height-fallback: 100px;

        flex-shrink: 0;
        margin: 0;
        width: calc((var(--thumb-height, #{$thumb-height-fallback}) + var(--thumb-spacing, #{$thumb-spacing-fallback})) * var(--slides-per-view-thumbs, 3));
        height: var(--thumb-height, #{$thumb-height-fallback});

        &._center-flex .swiper-wrapper {
            justify-content: center;
        }

        :global(.swiper-thumb) {
            opacity: 0.5;

            img {
                cursor: pointer;
                object-fit: contain;
                width: 100%;
                // width: var(--thumb-height, #{$thumb-height-fallback});
                height: var(--thumb-height, #{$thumb-height-fallback});
            }
        }

        :global(.swiper-slide-thumb-active) {
            opacity: 1;
        }
    }

}

// Arrows left and right for thumb images
:global(.swiper-thumb-navigation) {
    $translate: translate(var(--x-offset, 0), -50%);

    z-index: 1;
    position: absolute;
    transform: $translate;
    top: 50%;

    &:global(.swiper-thumb-prev) {
        left: get-spacing(6) !important;
        --x-offset: -100%;
        transform: $translate scaleX(-1);
    }

    &:global(.swiper-thumb-next) {
        right: get-spacing(6) !important;
        --x-offset: 100%;
        transform: $translate;
    }
}

@mixin buttonColoring {
    background-color: cssvar(color-object-weak);
    color: cssvar(color-primary) !important;

    &:hover {
        background-color: cssvar(color-object-base);
        color: cssvar(color-secondary);
    }
}

// Modal
.image-modal {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: cssvar(color-primary);
    z-index: 100;
    padding: get-spacing(4);
}

.close {
    box-sizing: initial !important;
    position: absolute;
    right: get-spacing(4);
    top: get-spacing(4);
    padding: get-spacing(2);
    z-index: 10;
    border-radius: 0 4px 0;

    @include buttonColoring;
}


.image-expanding {
    position: absolute;
    padding: 3px 20px;
    font-size: 14px;
    bottom: 0;
    right: 0;

    @include buttonColoring;
}

.base {

    &:hover,
    &:focus {
        .expand {
            opacity: 1;
        }
    }
}

.expand {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 6px 0 6px 0;
    padding: 5px 20px;
    font-size: 14px;
    opacity: 0;

    @include mixins.mobileDevice {
        opacity: 1;
    }

    transition: opacity 0.3s ease;
    border-radius: 6px 0 6px 0;

    &:hover,
    &:focus {
        opacity: 1;
    }

    @include buttonColoring;
}