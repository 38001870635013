@use '@danfoss/mosaic-sass/dist/entry' as *;

// Sortable table headers
$icon-sort-void: url('data:image/svg+xml; utf8, <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 10H0L4.5 16L9 10Z" fill="rgb(216, 216, 217)" /><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6L9 6L4.5 0L0 6Z" fill="rgb(216, 216, 217)" /></svg>');
$icon-asc-sorted: url('data:image/svg+xml;utf8,<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 10H0L4.5 16L9 10Z" fill="rgb(216, 216, 217)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6L9 6L4.5 0L0 6Z" fill="rgb(48, 48, 48)"/></svg>');
$icon-dsc-sorted: url('data:image/svg+xml;utf8,<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 10H0L4.5 16L9 10Z" fill="rgb(48, 48, 48)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6L9 6L4.5 0L0 6Z" fill="rgb(216, 216, 217)"/></svg>');

th.headline div {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    gap: get-spacing(2);

    &.sortable:not(.ascending),
    &.sortable:not(.descending) {
        span {
            cursor: pointer;
            user-select: none;
        }

        span:after {
            content: $icon-sort-void;
            display: block;
        }
    }

    &.sortable.ascending span:after {
        content: $icon-asc-sorted;
        display: block;
    }

    &.sortable.descending span:after {
        content: $icon-dsc-sorted;
        display: block;
    }
}