@use '@danfoss/mosaic-sass/dist/entry' as *;

.pagination {
    display: flex;
    justify-content: right;
    margin: get-spacing(2) 0;

    a {
        text-decoration: none !important;
    }

    &.hide-borders .df-pagination {
        margin: 8px 0;
        padding-right: 0;
        padding-left: 0;
        border-right: 0;
        border-left: 0;
    }
}