@use '@danfoss/mosaic-sass/dist/entry' as *;

.links,
.tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: get-spacing(2);
    gap: get-spacing(3);
}

.link {
    display: flex;
    flex-wrap: wrap;
    gap: get-spacing(1);
}

.link {
    text-decoration: none !important;

    .text {
        text-decoration: underline;
    }
}

.code {
    margin-top: get-spacing(2);
    margin-bottom: get-spacing(3);
    padding: get-spacing(2) !important;
    font-size: 20px !important;
}

.title {
    font-size: 20px !important;
}

.custom-hero {
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
    // margin-bottom: get-spacing(8);

    .image {
        width: 180px;

        @include match-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    .info-container {
        flex: 1;
        justify-content: center;

        // Subtitle 
        b {
            display: block;
            margin-top: get-spacing(2);
            margin-bottom: get-spacing(1);
        }
    }

}