@use '@danfoss/mosaic-sass/dist/entry' as *;

.section {
    border-top: 1px solid cssvar(color-neutral-base);
    border-bottom: 1px solid cssvar(color-neutral-base);
    padding-top: get-spacing(8);
    padding-bottom: get-spacing(8);

    .children {
        margin-top: get-spacing(8);
    }
}

.text {
    margin-top: get-spacing(4);
}