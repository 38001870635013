@use '@danfoss/mosaic-sass/dist/entry' as *;

$accordion-chevron-size: 24px;

:global(.df-accordion-header:not(.inline)) {
    .header {
        display: flex;
        width: 100%;
        padding-right: $accordion-chevron-size;
        justify-content: space-between;
    }
}
