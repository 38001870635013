.pdf-viewer {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
}

.error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader-wrapper {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    :global(.df-loader) {
        background-color: #fff !important;
    }
}