@use "@danfoss/mosaic-sass/dist/entry" as *;

.list-actions {
    width: 100%;
    display: flex;
    border-bottom: 1px solid cssvar(color-object-weak);
    gap: get-spacing(6);
    margin-bottom: get-spacing(2);
    padding: get-spacing(2) 0;
}

.actions {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: get-spacing(4);
    width: 100%;

    label {
        display: flex;
        align-items: center;
        flex: 0;
        gap: get-spacing(1);

        &:hover {
            cursor: pointer;
            color: cssvar(color-text-strong) !important;
        }
    }

    label._disabled,
    ._disabled {
        color: cssvar(color-object-weaker) !important;

        &:hover {
            cursor: not-allowed;

            :global(.material-icons-outlined) {
                color: cssvar(color-object-weaker) !important;
            }
        }

    }
}