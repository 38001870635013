@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './variables.scss' as v;
@use './helpers.scss' as h;

@mixin chat-header-height {
    --header-height: #{v.$sub-header-height-desktop};

    @include match-breakpoint-down(sm) {
        --header-height: #{v.$sub-header-height-mobile};
    }

    & {
        min-height: var(--header-height);
    }
}

@mixin chat-max-width {
    max-width: v.$chat-max-width;
    margin: 0 auto;
}

@mixin ellipsis($row) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $row;
}

@mixin thin-scroll($width: 4px, $color: cssvar(color-neutral-strong), $hoverColor: cssvar(color-neutral-stronger), $radius: 12px) {
    scrollbar-width: $width;

    &::-webkit-scrollbar-track {
        border-radius: $radius;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: $width;
        height: $width;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $radius;
        background-color: cssvar(color-neutral-strong);

        &:hover {
            background: cssvar(color-neutral-stronger);
        }
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

@mixin preformattedText($isChatWindow: false) {
    pre {
        line-height: 1.45;
        color: cssvar(color-primary);
        background-color: cssvar(color-secondary);
        overflow: auto;
        border-radius: 5px;
        max-height: 150px;
        padding: 12px;

        @if($isChatWindow) {
            padding: 16px;
            font-size: 85%;
            border-radius: 6px;
            max-height: unset;
        }
    }
}

@mixin mobileDevice {
    @media (hover: none) {
        @content;
    }
}

@mixin dropZoneHighlighter($fadeTimer: 450ms, $opacity: 0, $spacing: 3px) {
    $border: 3px;
    $dimensionWithMargins: calc(100% - #{$border * 2} - #{$spacing * 2});

    &::after {
        content: "";
        transition: opacity $fadeTimer ease;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        z-index: 1;
        height: $dimensionWithMargins;
        width: $dimensionWithMargins;
        border: $border dashed cssvar(color-object-weaker);
        opacity: $opacity;
        margin: $spacing;
    }
}