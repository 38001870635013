@use '@danfoss/mosaic-sass/dist/entry' as *;

.container {
    display: flex;
    width: 100%;
    gap: get-spacing(4);
    align-items: center;
    justify-content: space-between;

    .cta {
        margin-left: auto;
        flex-shrink: 0;
    }

    @include match-breakpoint-down('sm') {
        flex-wrap: wrap;
    }
}
