@use '@danfoss/mosaic-sass/dist/entry' as *;

// Rows stripes
.hidden {
    display: none;
}

.even {
    background: cssvar(color-surface-base) !important;
}

.odd {
    background: cssvar(color-primary) !important;
}


// Search
$accordion-transition: max-height 0.25s ease;
$icon-transition: opacity 0.35s ease;
$transition-icon-transform: transform .35s ease;
$transition-colors: color 0.25s ease, background-color 0.25s ease;
$menu-position-visible: translate(-19px, 0);

.table-wrapper {
    position: relative;
}

.search-wrapper {
    position: relative;
    overflow: hidden;

    transition: $accordion-transition;
    max-height: 0;

    &.expanded {
        max-height: 100px;
        transition: $accordion-transition;
        overflow: hidden;
    }
}

.miniscule-menu-icon {
    position: absolute;
    padding: 3px;

    background-color: lighten(rgba(255, 170, 0), 35%);
    transition: $transition-icon-transform;
    border: 1px solid cssvar(color-secondary) !important;
    border-radius: 3px 0 0 3px !important;

    top: 16px;
    left: -10px;

    span {
        opacity: 0;
        transition: $icon-transition;
    }

    &:hover {
        transform: $menu-position-visible;

        span {
            opacity: 1;
            transition: $icon-transition;
        }
    }

    // &:not(:hover),
    // &:not(:focus),
    // &:not(:active) {
    //     animation-name: bounce;
    // }

    // &:not(.miniscule-menu-active) {
    //     animation-delay: 3s;
    //     animation-duration: 0.6s;
    //     animation-iteration-count: 2;
    // }

    &.miniscule-menu-active {
        transform: $menu-position-visible;
        transition: $transition-colors;

        &:hover {
            transition: $transition-colors;
            // color: cssvar(color-primary);
            background-color: lighten(rgb(226, 0, 15), 35%);
        }

        span {
            opacity: 1;
        }
    }
}

.no-result {
    margin-bottom: 1rem;
    text-align: center;
    color: cssvar(color-accent-base);
    display: none;
}

// @keyframes bounce {
//     0% {
//         transform: translateX(0px);
//     }

//     50% {
//         transform: translateX(-2px);
//     }

//     100% {
//         transform: translateX(0px);
//     }
// }