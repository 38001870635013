@use '@danfoss/mosaic-sass/dist/entry' as *;

:global(.df-mosaic) .hero {
    --df-cmp-hero-card-background-image: url('../../../public/images/hero-default-image.jpg');
    --df-cmp-hero-card-image-height: 290px;

    position: relative;
    width: 100%;

    h1 {
        margin-bottom: get-spacing(3);
    }
}
