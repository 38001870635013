@use "@danfoss/mosaic-sass/dist/entry" as *;

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.file-input-progress {
    transition: opacity 0.3s ease;

    &.pulse {
        animation: pulse 1.5s infinite;
    }

    &.hidden {
        transition: opacity 0.3s ease;
        opacity: 0;
    }

    &.error div {
        background-color: cssvar(color-accent-weak) !important;
    }

    &.success div {
        background-color: cssvar(color-success) !important;
    }
}