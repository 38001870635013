@use '@danfoss/mosaic-sass/dist/entry' as *;

// Ensure this list is in sync with the type "Color" in `./colors.ts`
$color-map: (
    'red': cssvar(color-accent-base),
    light- 'red': cssvar(color-object-active),
    'blue': cssvar(color-info),
    'green': cssvar(color-success),
    'yellow': cssvar(color-warning),
    'grey': cssvar(color-neutral-strong),
    'black': cssvar(color-neutral-stronger),
    'white': cssvar(color-primary),
    'transparent': 'transparent'
);

@function get-mosaic-color($color) {
    @return map-get($color-map, $color);
}

@mixin loop-colors($property: color, $prefix: '') {
    $extra-selector: if($prefix !='', '#{$prefix}-', '');

    @each $color, $value in $color-map {
        &.#{$extra-selector}#{$color} {
            #{$property}: $value;
            @content;
        }
    }
}