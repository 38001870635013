@use "@danfoss/mosaic-sass/dist/entry" as *;

.file-input-wrapper {
    margin-bottom: get-spacing(3);
}

.file-drop-progress {
    margin-top: get-spacing(2);
}

.error {
    margin-top: get-spacing(3);
    margin-bottom: get-spacing(3);
}