@use '@danfoss/mosaic-sass/dist/entry' as *;

.message-actions {
    display: flex;
    gap: get-spacing(2);
    margin-top: 10px;

    svg {
        width: 16px !important;
        transition: 100ms color ease-in-out;
        cursor: pointer;
    }

    span {
        line-height: 24px;
        cursor: pointer;
    }

}

.separator {
    line-height: 0.9 !important
}

:global(.df-mosaic) .description {
    margin-bottom: get-spacing(4);
}