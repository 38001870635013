@use '@danfoss/mosaic-sass/dist/entry' as *;

.markdown-wrapper {

    // code,
    // pre {
    //     font-family: monospace !important;
    // }
    // p + table {
    //     margin-top: get-spacing(4);
    // }
    table {
        margin-top: get-spacing(3);
        margin-bottom: get-spacing(3);
    }
}

.content-container {
    min-height: 50px;
}