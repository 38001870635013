@use '@danfoss/mosaic-sass/dist/entry' as *;

:global(.df-mosaic :not(#\#)) .header {
    :global(.df-context-menu .df-link-tertiary) {

        &,
        &:not(:first-child),
        & > *,
        & > * > div {
            width: 100%;
            text-decoration: none;
            margin-left: 0;
        }
    }

    .user {
        cursor: pointer;
        display: flex !important;
        align-items: center;
    }
}

.hidden {
    display: none !important;
}

.list {
    > * {
        margin-left: 12px;
    }
}