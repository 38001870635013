@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/variables.scss' as v;

.page-layout {
    height: 100%;
    display: flex;
    flex-direction: column;

    :global(.df-header) {
        position: sticky !important;
    }

    .main-section {
        display: flex;
        overflow: hidden;
        flex: 1;
    }
}