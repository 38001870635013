.ribbon-message {
    background: rgb(204 233 255);
    justify-content: center;
    position: relative;
    display: flex;
    width: 100%;
    width: 100%;
    padding: 16px;
    z-index: 1;
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.text-container {
    width: 100%;

    b {
        font-weight: 600;
    }
}