@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins";
@use "../../styles/variables.scss" as var;

@mixin chatWindowBaseStyling {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    height: 100%;
    max-height: 100%;
    // overflow-y: hidden;

    .input-container {
        position: sticky;
        bottom: 0;
        background-color: cssvar(color-primary);
        width: 100%;
        padding: get-spacing(3) get-spacing(5);
        border-top: var(--border);

        .warning {
            @include mixins.chat-max-width;
            margin-bottom: get-spacing(3);
        }

        &.workflow-input {
            position: relative;
        }
    }
}

.chat-window {
    @include chatWindowBaseStyling;
}

.chat-window-wrapper {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
}

.chat-messages {
    margin-top: get-spacing(16);
    margin: 0 auto;
}

.new-chat-messages {
    @include mixins.chat-max-width;
    margin-top: get-spacing(12);
    padding: get-spacing(3) get-spacing(5);

    .recommendations {
        margin-top: get-spacing(10);
    }
}

.workflow-input {
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
    max-width: var.$chat-max-width;
}

.cta-container {
    position: sticky;
    bottom: 0;
    padding-top: get-spacing(4);
    padding-bottom: get-spacing(4);
    background-color: cssvar(color-primary);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
}