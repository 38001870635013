@use '@danfoss/mosaic-sass/dist/entry' as *;

.token-expired-prompt {
    z-index: 30; // 15 is sufficient, but I bumped it a bit if mosaic changes their z-indexes
    position: relative;
}

.tour-init {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}