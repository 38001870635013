@use '@danfoss/mosaic-sass/dist/entry' as *;

.label {
    margin-bottom: get-spacing(2);

    label {
        font-weight: 700 !important
    }
}

.search {
    margin-bottom: get-spacing(2)
}

.not-found {
    display: block;
    margin-top: get-spacing(4)
}

.table {
    td {
        margin: get-spacing(3) get-spacing(4) !important;
        padding: 0 !important;
    }


    &.initial-skeleton td {
        padding: get-spacing(3) get-spacing(4) !important;
    }
}

.skeleton {
    td {
        overflow: hidden;
        position: relative;
        border-radius: 4px;
        margin: get-spacing(3) get-spacing(4);
        color: transparent !important;
        background-color: rgba(225, 225, 225, .8)
    }

    td:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: get-spacing(4);
        right: get-spacing(4);
        animation: skeleton 1.8s infinite cubic-bezier(0.33, 1, 0.68, 1);
        background-image: linear-gradient(-75deg, rgba(#fff, 0) 0, rgba(#fff, .3) 20%, rgba(#fff, 0.2) 60%, rgba(#fff, 0));
        border-radius: 4px;
    }

    @keyframes skeleton {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(100%);
        }
    }
}