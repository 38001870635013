@use '@danfoss/mosaic-sass/dist/entry' as *;

:global(.df-mosaic :not(#\#)) .prompt {
    :global(.df-modal) {
        :global(.df-modal-container) {
            width: 100%;
            min-width: initial;
            max-width: min(500px, calc(100vw - #{get-spacing(8)})) !important;

            min-height: auto;

            margin: auto;
        }

        :global(.df-modal-body) {
            padding-right: 0 !important;
        }

        .actions {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: get-spacing(4);

            @include match-breakpoint-down(xss) {
                flex-wrap: wrap;
                button {
                    margin: 0;
                }
            }
        }
    }
}
