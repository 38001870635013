@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './../../styles/variables.scss' as v;
@use './../../styles/mixins.scss' as mixins;

@mixin right-sidebar-background {
    $color: cssvar(color-neutral-weak);
    background-color: $color;

    :global(.df-menu-item) {
        background-color: $color !important
    }
}

.chat-view-layout {
    display: flex;
    width: 100vw;
    justify-content: space-between; // Fixes scenario when right sidebar is open and the main content is not visible since it is in non-existing error state. (Prevents right side panel 'merging' with the left one)  
    position: relative;

    // SCENARIO (Tablet)
    .right-sidebar {
        &:global(._sidebar-open) {
            max-width: calc(100% *0.5) !important;
        }

        @include right-sidebar-background;
    }

    // Hide right sidebar in the stack once it is not visible

    // SCENARIO (Desktop)
    @include match-breakpoint-up(lg) {
        .right-sidebar {
            &:global(._sidebar-open) {
                max-width: calc((100% - #{v.$left-sidebar-max-width})* 0.5) !important;
            }

            @include right-sidebar-background;
        }
    }

    // Tablet when right sidebar is open
    .main:has(+ .right-sidebar:global(._sidebar-open)) {
        width: calc((100% - #{v.$left-sidebar-max-width})* 0.5) !important;
    }

    // Table
    .main {
        width: 100%;
        display: contents;
    }

    // SCENARIO (Mobile)
    @include match-breakpoint-down(sm) {
        .right-sidebar {
            &:global(._sidebar-open) {
                max-width: 90% !important
            }
        }

        .main {
            width: 100%;
        }
    }

    @include match-breakpoint-down(xs) {
        .right-sidebar {
            &:global(._sidebar-open) {
                max-width: 100% !important
            }
        }

        .main {
            width: 100%;
        }
    }
}

// Overwrite the default styles
.right-sidebar.exv-embed,
.right-sidebar.txv-embed {
    // Embedded color override        
    background-color: cssvar(color-primary) !important;

    :global(.df-menu-item) {
        background-color: cssvar(color-primary) !important;
    }
}


.cta {
    justify-content: space-between !important;
    width: 100%;

    span:first-child {
        @include mixins.ellipsis(1);
    }
}

// .header-icons {
//     display: flex;
//     flex: 0;
//     gap: get-spacing(4);
//     width: 100%;
// }

// .headline {
//     @include mixins.ellipsis(1);
// }

// Remove padding from the pdf template
.pdf {
    > * {
        padding: 0 !important;
        overflow: hidden !important;
    }
}

// .chat-history-headline {
//     display: block;
//     padding: get-spacing(5) get-spacing(4) get-spacing(2);
// }