@use '../../styles/mixins.scss' as mixin;

:global(.df-mosaic :not(#\#)) .modal {
    display: contents;

    &.hide-footer footer,
    &.hide-body :global(.df-modal-body) {
        display: none;
    }
}

.modal :global(.df-modal-body) {
    @include mixin.thin-scroll;
    padding-right: 2px !important;
}