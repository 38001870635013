@use "sass:math" as math;
@use "@danfoss/mosaic-sass/dist/entry" as *;

$spacing: get-spacing(7);

// .wrapper {
// height: 100%;
// padding-bottom: $spacing;

// > :first-child {
// margin-bottom: $spacing;
// }
// }

.file-drop-area {
    margin-bottom: get-spacing(3);
}

.file-drop-progress {
    margin-top: get-spacing(2);
}

.error {
    margin-bottom: get-spacing(3);
}