@use '@danfoss/mosaic-sass/dist/entry' as *;

.chat-welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title {
        margin-top: get-spacing(5);
        font-size: 24px;
        font-weight: 600;
    }
}