@use './styles/helpers.scss' as h;

html,
body,
#root {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.df-header .df-container.df-header-container {
    max-width: none !important;
}

// Override new mosaic style of selector
.df-mosaic section {
    display: flex !important;
}

/* Used in `Source/src/ui/markdown-wrapper/helpers.ts` for creating scroll on large tables */
.overflow-x-scroll {
    overflow-x: scroll;
}

.menu-wrapper {
    position: relative;
    overflow: visible;
    display: grid; // Since table is having 'max-width: max-content' it is somehow forcing width of the side panel not to be respected.
}

/* Needed to work around the mobile browser viewport height issue */
.df-mosaic :not(#\#) .df-site {
    min-height: calc(h.vh(100) - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    max-height: calc(h.vh(100) - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}