@use '@danfoss/mosaic-sass/dist/entry' as *;

.form-styling {

    fieldset>* {
        margin-bottom: get-spacing(2);
    }

    >* {
        margin-bottom: get-spacing(2);
    }

    margin-bottom: get-spacing(2);
}

.select-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 6px;

    label {
        font-weight: 600;
    }
}