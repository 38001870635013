@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/mixins.scss' as mixins;

/* Below is from the light stylesheet: https://github.com/sindresorhus/github-markdown-css */

.df-mosaic .markdown-body {
    line-height: 1.6;

    // Reset headline size so that rendered MD wouldn't break the design
    h1 {
        font-size: 1.3rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }

    pre,
    pre *,
    code,
    code * {
        font-family:
            SFMono-Regular,
            Consolas,
            Liberation Mono,
            Menlo,
            monospace;
    }

    @include mixins.preformattedText(true);

    code,
    tt {
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        white-space: break-spaces;
        background-color: rgba(175, 184, 193, 0.2);
        border-radius: 6px;
    }

    pre > code {
        padding: 0 !important;
        margin: 0 !important;
        word-break: normal;
        white-space: pre;
        background: transparent;
        border: 0;
    }

    pre code {
        font-size: 100%;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    ol,
    ul {
        margin: get-spacing(3) 0;
        padding-left: get-spacing(8) !important;
    }

    // In case images are rendered inside __li__ elements remove the bullet point
    ul li:has(span>img) {
        list-style: none;
    }

    ul:has(li span>img) {
        padding-left: 0 !important;
    }

    img {
        display: block;
        max-width: 200px;
        max-height: 300px;
        max-width: 100%;
    }

    a:not([href]) {
        color: inherit;
        text-decoration: none;
        cursor: inherit;
    }

    table {
        min-width: 100%;
        width: max-content !important;

        thead {
            z-index: 1;
        }

        // Highlight on hover
        tr:has(td) {
            transition: background 0.15s ease-in-out;

            &:hover,
            &:active {
                background-color: cssvar(color-neutral-base) !important;
            }
        }

        // Enable horizontal resize for headers
        tr th {
            overflow: auto;
            resize: horizontal;
        }

        th {
            min-width: 60px;
            width: fit-content;
        }
    }

    input {
        box-shadow: none !important;
    }

    // Sticky headers
    .overflow-x-scroll:has(.df-table) {
        margin: 12px 0;
        max-height: 600px;

        table {
            margin: 0;
            width: 100%;
            border-collapse: separate !important; // Enforce border style on sticky headers

            thead {
                position: sticky;
                top: 0;

                th:not(:first-child) {
                    border-left: 0px solid transparent !important; // Remove colliding borders
                }
            }
        }
    }

    df-alert {
        display: block;
    }

    // Image captions
    .image-load-error {
        background-color: cssvar(color-object-weak);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIyNHB4IiBmaWxsPSIjZThlYWVkIj48cGF0aCBkPSJtODQwLTIzNC04MC04MHYtNDQ2SDMxNGwtODAtODBoNTI2cTMzIDAgNTYuNSAyMy41VDg0MC03NjB2NTI2Wk03OTItNTZsLTY0LTY0SDIwMHEtMzMgMC01Ni41LTIzLjVUMTIwLTIwMHYtNTI4bC02NC02NCA1Ni01NiA3MzYgNzM2LTU2IDU2Wk0yNDAtMjgwbDEyMC0xNjAgOTAgMTIwIDMzLTQ0LTI4My0yODN2NDQ3aDQ0N2wtODAtODBIMjQwWm0yOTctMjU3Wk00MjQtNDI0WiIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 48px;
        cursor: not-allowed;

        button,
        img {
            display: none !important;
        }
    }
}