@use '@danfoss/mosaic-sass/dist/entry' as *;
@use '../../styles/variables.scss' as v;

:global(.df-mosaic :not(#\#)) .button {
    display: flex;
    align-items: center;
    gap: get-spacing(2);
    transition: v.$button-color-transition;

    :global(.df-icon) {
        pointer-events: none;
    }

    &.df-text-btn {
        @include make-typography-body;
    }

    &._place-icon-start {
        span:first-of-type {
            order: 1;
            line-height: 1;
        }
    }

    &.full-width {
        width: 100%;
    }

    &.loading {
        :global(.df-icon) {
            height: 24px;
            width: 24px;

            svg {
                fill: currentColor;
            }
        }
    }

    &:disabled {
        cursor: not-allowed;
    }

    // Extend mosaic hover effect to accommodate for the Icon fill property.
    &:global(.df-text-btn-secondary:not(#\#):hover) {
        fill: cssvar(color-active-base);
    }

    // Custom button themes
    &:global(.custom-light-dark-to-dark) {
        color: cssvar(color-text-strong);
        fill: cssvar(color-text-strong);

        &:hover {
            color: cssvar(color-secondary);
            fill: cssvar(color-secondary);
        }
    }
}
