@use "@danfoss/mosaic-sass/dist/entry" as *;

.content {
    margin-top: get-spacing(2);

    > * {
        display: block;
        margin-bottom: get-spacing(3);
    }
}

.information {
    padding-bottom: get-spacing(6);

    :global(.df-accordion-header) {
        padding-right: get-spacing(10) !important;

        &::after {
            margin: 0 !important;
        }
    }
}