@use '@danfoss/mosaic-sass/dist/entry' as *;

.status-header {
    // margin-bottom: get-spacing(1.5);
    display: flex;
    align-items: center;
    line-height: 1;

    label {
        display: flex;
        align-items: center;
    }
}