@use '@danfoss/mosaic-sass/dist/entry' as *;

:global(.df-mosaic) .tag {
    pointer-events: none;
    user-select: none;
    line-height: 1;
    background-color: cssvar(color-accent-base);
    color: cssvar(color-primary);
    border: 2px solid cssvar(color-primary);
    font-weight: 900;
    padding: 2px 3px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 0.7rem;

    &._no-border {
        border: 0;
    }
}
