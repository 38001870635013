@use "@danfoss/mosaic-sass/dist/entry" as *;

.chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: get-spacing(2);

    > * {
        span {
            margin: auto;
            line-height: 1;
        }
    }
}