@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins.scss" as mixins;

.details {
    @include mixins.preformattedText;
}

.notifications {

    // Notification
    > div:not(:last-of-type) {
        margin-bottom: get-spacing(2);
    }
}