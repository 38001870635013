$header-height-desktop: 72px;
$header-height-mobile: 52px;

$sub-header-height-desktop: 60px;
$sub-header-height-mobile: 48px;

$sub-header-box-shadow: 0 0 5px -2px black;
$ui-box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.3);

$left-sidebar-max-width: 340px;
$right-sidebar-width: 600px;
$chat-max-width: 800px;

$transition-sm: 0.1s ease-in-out;

$button-color-transition: color $transition-sm, fill $transition-sm; // Accommodate Icon/SVG color transition