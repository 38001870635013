@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './../../styles/variables.scss' as v;
@use './../../styles/mixins.scss';

.container {
    position: relative;
    box-shadow: 0 0 5px -3px black;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    background-color: white !important;
    cursor: default !important;
    @include mixins.chat-header-height;

    .wrapper {
        display: flex;
        gap: get-spacing(4);
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}