@use '@danfoss/mosaic-sass/dist/entry' as *;
@use './../../styles/variables.scss' as v;
@use './../../styles/mixins.scss' as m;

:global(.df-mosaic) .temperature-slider {
    label {
        margin-bottom: -10px;
    }

    .captions {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: get-spacing(3);

        >span {
            text-align: center;
            font-size: 14px;
            @include m.ellipsis(1);

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}

:global(.df-mosaic .df-accordion) .temperature-slider label {
    margin-top: get-spacing(2);
}