@use "@danfoss/mosaic-sass/dist/entry" as *;
@use '../new-chat-window/NewChatWindow.module.scss' as n;
@use '../../styles/variables.scss' as v;
@use '../../styles/mixins.scss' as mixins;

.workflow {
    @include n.chatWindowBaseStyling;
    overflow: hidden;
    position: relative;

    .wrapper {
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
    }

    .content {
        @include mixins.chat-max-width;
        max-width: v.$chat-max-width * 2;
        margin-top: get-spacing(6);
        padding: get-spacing(3) get-spacing(5);
        width: 100%;

        :global(.df-alert) {
            margin: 0 auto;
            max-width: v.$chat-max-width;
        }
    }



}