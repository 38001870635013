@use '@danfoss/mosaic-sass/dist/entry' as *;

$fs: 14px;

.description {
    font-size: $fs;

    .toggle-button {
        display: inline !important;
        min-height: unset !important;
        line-height: 1;
        font-size: $fs;
    }
}