.loader {
    height: 100%;

    &.relative {
        position: relative;
    }

    &:not(.inline) {
        display: block;
    }

    >div {
        z-index: 1; // Added since SidebarHeader and ChatInput was above the loader
    }
}