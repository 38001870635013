@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins.scss";

.root {
    filter: contrast(101%);
    background: cssvar(color-active-base);
    height: 100vh;
    width: 100vw;
    position: relative;
    user-select: none;

    video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
    }


    &.ready:not(.playing) {
        .intro {
            .play {
                transition-delay: 2s;
                opacity: 1;
            }
        }
    }

    &.loading {
        .intro {
            .spinner {
                transition-delay: 3s;
                opacity: 1;
            }
        }
    }


    &.hide-intro {
        .intro {
            opacity: 0;
        }

        .overlay {
            display: block;
        }
    }
}

.overlay {
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: transparent;
    display: none;

    .icon {
        bottom: 0;
        transform: scale(0.5);
        position: absolute;
        opacity: 0.7;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }

        &:first-child {
            right: 50px;
        }

        &:last-child {
            right: 0;
        }
    }

    button {
        position: absolute;
        color: #fff !important;
        border-color: #fff !important;
        left: 50%;
        top: calc(50% + 75px);
        transform: translate3d(-50%, -50%, 0);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
        transition: margin 0.1s ease;
        animation: fadeInAnimation ease 1s;
        max-width: 180px;

        &:hover {
            margin-top: 1px;
            margin-left: 1px;
        }

        &:active {
            margin-top: 0px;
            margin-left: 0px;
        }

        @keyframes fadeInAnimation {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}

.intro {
    filter: contrast(101%);
    background: cssvar(color-active-base);
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: opacity 0.35s ease-in-out;

    h1 {
        font-weight: normal !important;
    }

    .icon {
        position: absolute;
        top: calc(50% + 50px);
        opacity: 0;
        transition: opacity 0.35s ease-in-out;
    }
}

.icon {
    border: 4px solid #fff;
    padding: 0;
    width: 60px !important;
    height: 60px !important;
    display: block;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
    background-color: cssvar(color-active-base);

    &.white {
        background-color: #fff;
    }
}