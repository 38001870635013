@use "@danfoss/mosaic-sass/dist/entry" as *;

.insights {
    padding-bottom: get-spacing(4) !important;

    :global(.df-timeline-steps) {
        display: grid !important;
        grid-template-columns: auto minmax(0, 1fr);
        &:last-child {
            .insight {
                padding-bottom: 0 !important;
            }
        }
    }
}

.insight {
    padding-bottom: 10px !important;
    h3 {
        line-height: 1.4;
        margin-bottom: get-spacing(1);
        font-size: 18px;
        display: flex;
        align-items: center;
    }

    ul {
        list-style-type: circle;
        padding-left: 15px;
    }
    :global(.df-accordion-header) {
        //color: rgba(black, 0.5) !important;
        font-weight: normal !important;
        margin-bottom: 0 !important;
        &:after {
            right: auto !important;
            position: relative !important;
        }
    }
}

.content-wrapper {

    padding-top: get-spacing(2);
    padding-bottom: get-spacing(2);
    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(black, 0.3);
    }
    pre {
        margin-top: get-spacing(1);
        max-height: 300px;
    }
}

.accordion {
    display: block;
    margin-top: get-spacing(1);

    :global(.df-accordion-header) {
        font-size: 14px !important;
        margin-bottom: get-spacing(2);
    }

    :global(.df-accordion-content-wrapper) {
        margin-top: get-spacing(2);
        padding: get-spacing(1) get-spacing(5);
        background-color: cssvar(color-object-weaker);
        border-radius: 4px;
    }
}
