@use '@danfoss/mosaic-sass/dist/entry' as *;

@mixin shared-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin navigation-query($first-child-order: 1) {
    @container navigation-container (max-width: 300px) {
        @include match-breakpoint-down(sm) {
            :global(.df-btn-secondary) {
                width: 100% !important;
            }

            :global(.df-text-btn-secondary) {
                width: 100%;
            }

            :first-child {
                order: $first-child-order;
            }
        }

        @content;
    }
}


.tooltip-container {
    display: block;
    width: calc(100vw - 80px);
    max-width: 400px;
    background: cssvar(color-primary);
    padding: get-spacing(2) get-spacing(4) get-spacing(4);
}

.navigation {
    @include shared-flex;
    flex-wrap: wrap;
    container-name: navigation-container;
    container-type: inline-size;

    margin-top: get-spacing(4);
    row-gap: get-spacing(2);
    column-gap: get-spacing(6);

    @include navigation-query(3)
}

.secondary-navigation {
    @include shared-flex;
    justify-content: flex-end;
    gap: get-spacing(2);
    flex: 1;

    // Don't allow mosaic buttons to stretch all the way on small screens
    @include match-breakpoint-down(sm) {
        :global(.df-btn-secondary) {
            width: auto !important;
        }
    }

    @include navigation-query() {
        flex-wrap: wrap;
    }
}

.header {
    @include shared-flex;
    align-items: flex-start;
    line-height: 1;

    h5 {
        font-size: 1rem !important;
        line-height: 1.4 !important;
        padding-right: get-spacing(1);
    }

    span {
        flex: none;
    }
}

.content {
    margin-top: get-spacing(2);
}