@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins" as mixins;

.information-view {
    overflow-y: auto !important;
    @include mixins.thin-scroll;

    img {
        // width: unset !important;
    }
}

.content-wrapper {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
}