@use "@danfoss/mosaic-sass/dist/entry" as *;
@use "../../styles/mixins";

.information {
    > *:not(:first-of-type) {
        margin: get-spacing(15) 0 !important;
    }
}

.timeline {
    row-gap: 0 !important;
    column-gap: get-spacing(7) !important;

    :global(.df-timeline-container) {
        padding-top: 8px !important;
    }
}

.timeline-content {

    h3 {
        line-height: 1;
    }

    .date {
        margin-bottom: get-spacing(2.5);
        margin-top: get-spacing(-3);
    }

    :global(.df-accordion) {
        margin-bottom: get-spacing(5);
    }

    :global(.df-accordion-header) {
        font-size: 0.9rem !important;

        &:after {
            margin-left: get-spacing(3);
            position: relative !important;
        }
    }

}

.about {
    ul {
        line-height: 1.5;
        padding-left: 1rem !important;
        list-style: disc !important;
    }
}

.wrapper-with-line-break {
    > * {
        $p: get-spacing(15);
        padding-bottom: $p;
        padding-top: $p;
        border-top: 1px solid cssvar(color-secondary);
    }
}

.anchor-headline {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: get-spacing(3);
    margin-bottom: get-spacing(2) !important;

    @include match-breakpoint-up(sm) {
        margin-top: get-spacing(2) !important;
    }

    .anchor-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: cssvar(color-primary);
        color: cssvar(color-active-base);
        border: 1px solid cssvar(color-secondary);
        border-radius: 24px;
        padding: 14px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        .anchor-icon {
            opacity: 1;
        }

        color: cssvar(color-active-base);
        opacity: 1;
    }
}

.highlighted {
    color: cssvar(color-active-base);
}

.sticky-tabs {

    :global(.df-tab),
    :global(.df-tabs-nav) {
        background-color: transparent !important;
        z-index: 1;
    }
}

.disclaimer {
    display: block;
    margin-bottom: get-spacing(4);
}